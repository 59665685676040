<!--========================================================
														CONTENT 
	=========================================================-->
<section id="content">

	<div class="container">
		<div class="row">
			<div class="grid_8  wow fadeInLeft" >
				<!-- start Camera -->
				<div class="camera_main_container  wow fadeIn" >
					<!-- <div class="slider_wrapper">
						<div class="" id="camera_wrap">
							<div data-src="assets/images/slide1.png">
							</div>

							<div data-src="assets/images/slide2.jpg">
							</div>

							<div data-src="assets/images/slide3.jpg">
							</div>
							<div data-src="assets/images/slide4.jpg">
							</div>

						</div>
					</div> -->
					<owl-carousel-o [options]="customOptions2">
 
						<ng-container *ngFor="let slide of camaraImgList">
						  <ng-template carouselSlide [id]="slide.id">
							<img [src]="slide.url" [alt]="slide.caption" [title]="slide.caption" [width]="768" [height]="504">
						  </ng-template>
						</ng-container>
					 
					  </owl-carousel-o>
				</div>
				<!-- end Camera -->
			</div>
			<div class="grid_4  wow fadeInRight" data-wow-delay="3s">
				<img src="assets/images/WolfClaimed-PaperBack-Med.png" width="650" height="813" class="img2">
			</div>
		</div>
	</div>
	<div class="container">

		<div class="wrapper txt_cntr  wow fadeInLeft">
			<p class="txt2">Welcome!</p>
		</div>
		<p class="txt3  wow fadeInRight">Alpha Witch is a Romance Publishing company. </p>


		<div class="row txt_cntr">
			<div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
				<img src="assets/images/page1_icon1.png" alt="" class="img3 no_resize">
				<h3>Romance Paranormal</h3>
				<p>Bringing you the Alphas of your dreams.</p>
				<!--<a href="#" class="more_btn">Read More</a> -->
			</div>

			<div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.2s">
				<img src="assets/images/page1_icon2.png" alt="" class="img3 no_resize">
				<h3>Romance Contemporary</h3>
				<p>Sexy stories about women who know what they want and the men who want to give it to them.</p>
				<!--<a href="#" class="more_btn">Read More</a> -->
			</div>

			<div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.4s">
				<img src="assets/images/page1_icon3.png" alt="" class="img3 no_resize">
				<h3>Romance Sci Fi </h3>
				<p>Sci Fi with passionate love!</p>
				<!--<a href="#" class="more_btn">Read More</a> -->
			</div>

			<div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
				<img src="assets/images/page1_icon4.png" alt="" class="img3 no_resize">
				<h3>Erotic</h3>
				<p>Pushing the boundaries of fiction. </p>
				<!-- <a href="#" class="more_btn">Read More</a> -->
			</div>

		</div>



		<h2 class="txt_cntr">New releases</h2>
		<div class="row txt_cntr">
			<div class="grid_4 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
				<!-- <img src="WitchBetrayed2017_Large.jpg"  class="img4"> -->
				<iframe type="text/html" width="336" height="550" frameborder="0" allowfullscreen style="max-width:100%"
					src="https://read.amazon.com/kp/card?asin=B01LZU9T98&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_2RxrzbQ72EA2W"></iframe>
				<a [routerLink]="[ '/books']" class="link_arr">Witch Betrayed</a>
			</div>

			<div class="grid_4 wow fadeInUp" data-wow-duration="" data-wow-delay="0.3s">
				<img src="assets/images/Evince-Large.jpg" class="img4">
				<a [routerLink]="[ '/books']" class="link_arr">Evince</a>
			</div>

			<div class="grid_4 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
				<!--<img src="assets/images/Farbo.jpg" alt="" class="img4"> -->
				<iframe type="text/html" width="370" height="550" frameborder="0" allowfullscreen style="max-width:100%"
					src="https://read.amazon.com/kp/card?asin=B01680T0P6&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_fZOPwb1730MPH"></iframe>
				<a [routerLink]="[ '/books']" class="link_arr">Farbo</a>
			</div>
		</div>


		<h2 class="txt_cntr">Sexy and Good....</h2>
		<div class="box-1  wow fadeInUp">
			<div class="row">
				<div class="grid_10 preffix_1">

					<owl-carousel-o [options]="customOptions">
						<ng-template carouselSlide id="firstslide">
							<div>
								<iframe type="text/html" width="336" height="550" frameborder="0" allowfullscreen
									style="max-width:100%"
									src="https://read.amazon.com/kp/card?asin=B07B7JGCHB&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_jykhBb4216ZVH"></iframe>
								<blockquote>

									<p>...I’ve been pretending for too long. Now I don’t have a choice but to make good
										on my promises. Things are about to get rough at the RedMorder, my Blood Den in
										Las Vegas. I don’t know if I’ll be able to keep it together. But I’m not going
										to lie down and be beat.....</p>
								</blockquote>

							</div>
						</ng-template>
						<ng-template carouselSlide id="secondslide">
							<div>
								<iframe type="text/html" width="336" height="550" frameborder="0" allowfullscreen
									style="max-width:100%"
									src="https://read.amazon.com/kp/card?asin=B072SNXBCK&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_nFkhBbQ8171DD"></iframe>
								<blockquote>

									<p>....I never wanted this. Never chose this. And then he came along. I'm not the
										damsel in distress type, but I can't help but trust a male with so much calm.
										His eyes are the stillest pond in the dark of night. His body is the haven I've
										craved since I can remember. ...</p>
								</blockquote>

							</div>
						</ng-template>
						<ng-template carouselSlide id="thirdslide">
							<div>
								<iframe type="text/html" width="336" height="550" frameborder="0" allowfullscreen
									style="max-width:100%"
									src="https://read.amazon.com/kp/card?asin=B07B42TW6V&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_0GkhBbBED8ZCR"></iframe>
								<blockquote>

									<p>...She's the reason I'm still alive. Anyone else would have reached for the fade
										long ago. I've survived all this time through determination, and plain old
										cantankerous grit. They won't break me. But she could. She's like the blood in
										my veins. ....</p>
								</blockquote>

							</div>
						</ng-template>
					</owl-carousel-o>
				</div>
			</div>
		</div>

		<div class="hline"></div>


		<div class="row">
			<div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
				<h2 class="letter_space0">Romance that sizzles</h2>

			</div>

			<div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.2s">
				<h2 class="letter_space0">Erotic that Burns</h2>

			</div>

			<div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.4s">
				<h2 class="letter_space0">Alpha's that give you all they've got</h2>

			</div>

			<div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.5s">

				<p class="txt4 letter_space0 color3">email:<br><span class="fz18"><a href="#"
							class="link">info&#64;alphawitch.com</a></span></p>
			</div>

		</div>



	</div>



</section>