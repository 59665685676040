<!--========================================================
														CONTENT 
	=========================================================-->
<section id="content">
	<div class="container">


		<div class="row txt_cntr">
			<div class="grid_8">
				<div class="map">
					<figure class="map">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d24214.807650104907!2d-73.94846048422478!3d40.65521573400813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1395650655094"
							style="border:0"></iframe>
					</figure>
				</div>

			</div>

			<div class="grid_4 wow fadeInRight" data-wow-duration="" data-wow-delay="0.9s">
				<h2 class="txt_cntr marTop2">Address</h2>
				<p class="txt8">2443 Fillmore St #380-9091 | <br>San Francisco, CA 94115.</p>
				<!--<p class="txt9"><span class="fz30">call us:</span><br>+1 800 559 6580<br>+1 800 589 4325</p> -->
				<p class="txt10"><span class="fz30">email:</span><br><a href="mailto:"
						class="link">info&#64;alphawitch.com</a></p>



			</div>
		</div>

		<!-- <div class="hline v7"></div> -->



		<!-- <div class="wrap">
			<h2 class="txt_cntr marTop2">Let us know what your thinking:</h2>

			<form id="contact-form">
				<div class="contact-form-loader"></div>
				<fieldset>

					<div class="row">
						<div class="grid_4">
							<label class="name">
								<input type="text" name="name" placeholder="Your Name:" value=""
									data-constraints="@Required @JustLetters" />
								<span class="empty-message">*This field is required.</span>
								<span class="error-message">*This is not a valid name.</span>
							</label>
						</div>

						<div class="grid_4">
							<label class="phone">
								<input type="text" name="phone" placeholder="Telephone:" value=""
									data-constraints="@Required @JustNumbers" />
								<span class="empty-message">*This field is required.</span>
								<span class="error-message">*This is not a valid phone.</span>
							</label>
						</div>

						<div class="grid_4">
							<label class="email">
								<input type="text" name="email" placeholder="E-mail:" value=""
									data-constraints="@Required @Email" />
								<span class="empty-message">*This field is required.</span>
								<span class="error-message">*This is not a valid email.</span>
							</label>
						</div>

					</div>
					<div class="row">
						<div class="grid_8">
							<label class="message">
								<textarea name="message" cols="100" rows="10" placeholder="Message:"
									data-constraints='@Required @Length(min=20,max=999999)'></textarea>
								<span class="empty-message">*This field is required.</span>
								<span class="error-message">*The message is too short.</span>
							</label>
						</div>
					</div>
					<div class="row">
						<div class="grid_8">
							<div class="btns">
								<a class="more_btn" data-type="reset">Clear</a>
								<a class="more_btn" data-type="submit">Submit</a>
							</div>
						</div>
					</div>
				</fieldset>
				<div class="modal fade response-message">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<button type="button" class="close" data-dismiss="modal"
									aria-hidden="true">&times;</button>
								<h4 class="modal-title">Modal title</h4>
							</div>
							<div class="modal-body">
								You message has been sent! We will be in touch soon.
							</div>
						</div>
					</div>
				</div>
			</form>
		</div> -->

















	</div>



</section>