	<!--========================================================
														CONTENT 
	=========================================================-->
	<section id="content">  

		<div class="container txt_cntr">
			<h2 class="txt_cntr marTop2">Privacy Policy</h2>
			<h3 class="lh24">This website does not collect visitor information.</h3>
		
<h3 class="lh24">Third-Party Advertisers and Links to Other Websites</h3>
<p>Our site includes third-party advertising and links to other websites. We do not provide any personally identifiable customer information to these advertisers or third-party websites. These third-party websites and advertisers, or Internet advertising companies working on their behalf, sometimes use technology to send (or "serve") the advertisements that appear on our website directly to your browser. They automatically receive your IP address when this happens. They may also use cookies, JavaScript, web beacons (also known as action tags or single-pixel gifs), and other technologies to measure the effectiveness of their ads and to personalize advertising content. We do not have access to or control over cookies or other features that they may use, and the information practices of these advertisers and third-party websites are not covered by this Privacy Policy. Please contact them directly for more information about their privacy practices.
</p>
<h3 class="lh24">Minor Customers</h3>
<p>This website is not intended for use by children. Any services available through our sight are meant for use by adults over the age of 18. If you are under 18, you may use alphawitch.com only with the approval and help of a parent or guardian.</p>

<h3 class="lh24">Conditions of Use, Notices, and Revisions</h3>
<p>If you choose to visit alphawitch.com, your visit and any dispute over privacy is subject to this Policy and our Terms and Conditions, including limitations on damages, arbitration of disputes, and application of the law of the state of California. If you have any concern about privacy at this website, please contact us with a thorough description, and we will try to resolve it.
Our business changes constantly, and our Privacy Policy and the Terms and Conditions will change also. You should check our Web site frequently to see any changes. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and/or your account. We stand behind the promises we make, however, and will never change our policies and practices to make them less protective of client information collected in the past without the consent of affected customers.</p>

<h3 class="lh24">Information from Other Sources</h3>
<p>Examples of information we receive from other sources include updated delivery and address information from our carriers or other third parties, which we use to correct our records and deliver your next purchase or communication more easily. </p>


			<p class="txt12"><a  href="mailto:info@alphawitch.com" class="link4">info&#64;alphawitch.com</a></p>
		</div>



	</section>