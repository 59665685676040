<!--========================================================
														CONTENT 
	=========================================================-->
<section id="content">

  <div class="container">


    <div class="box-2  wow fadeInUp">
      <h2 class="txt_cntr marTop7 color1">Mercedes bleau: Browse by series</h2>

      <div class="col2">
        <ul class="list2">
          <li><a href="#magkaen" appAnchorScroll>Books of the MagKaen</a></li>

        </ul>
      </div>

      <div class="col2">
        <ul class="list2">
          <li><a href="#nareth" appAnchorScroll>Kingdom of Nareth</a></li>

        </ul>
      </div>

      <div class="col2">
        <ul class="list2">
          <li><a href="#sa" appAnchorScroll>Stand Alone</a></li>

        </ul>
      </div>

      <div class="col2">
        <ul class="list2">

        </ul>
      </div>
    </div>

    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2"><a id="magkaen"></a>books of the magkaen</h2>


    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">
          <img src="assets/images/WolfClaimed2020-Final-Small.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Wolf Claimed</a></h3>
          <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <!-- <a href="https://www.barnesandnoble.com/w/wolf-claimed-mercedes-bleau/1119977070?ean=2940046131185"
              class="link2" target="_blank">E-Book; </a> -->
              <a href="http://a.co/jhI0E2f" class="link2"
              target="_blank">Kindle</a></p>
        </div>

      </div>

      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <img src="assets/images/WitchBetrayed2020_Final_Small.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Witch Betrayed</a></h3>
          <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <!-- <a href="https://www.barnesandnoble.com/w/witch-betrayed-1-mercedes-bleau/1124649984?ean=2940153736778"
              class="link2" target="_blank">E-Book; </a> -->
              <a
              href="https://www.amazon.com/Witch-Betrayed-Episode-Books-MagKaen-ebook/dp/B01LZU9T98" class="link2"
              target="_blank">Kindle</a></p>
        </div>

      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">

      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">

      </div>


    </div>


    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2"><a id="nareth"></a>Kingdom of nareth</h2>


    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">
          <img src="assets/images/Lusten4@0,25x.jpg" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Lusten</a></h3>
          <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <!-- <a href="https://www.amazon.com/Mercedes-Bleau/e/B00LUDGSDY" class="link2" target="_blank">E-Book; </a> -->
            <a
              href="https://www.amazon.com/Mercedes-Bleau/e/B00LUDGSDY" class="link2" target="_blank">Kindle</a></p>
        </div>

        <div class="wrapper">
          <img src="assets/images/BloodMother-Draft3-SMALL-350.jpg" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Blood Mother</a></h3>
          <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <!-- <a href="https://www.barnesandnoble.com/s/mercedes+bleau?_requestid=4774371" class="link2"
              target="_blank">E-Book; </a> -->
              <a
              href="https://www.amazon.com/Blood-Mother-Kingdom-Nareth-Book-ebook/dp/B07B7JGCHB/ref=sr_1_5?ie=UTF8&qid=1528603370&sr=8-5&keywords=mercedes+bleau"
              class="link2" target="_blank">Kindle</a></p>
        </div>

      </div>

      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <img src="assets/images/Farbo5@0,25x.jpg" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Farbo</a></h3>
          <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <!-- <a href="https://www.barnesandnoble.com/w/farbo-mercedes-bleau/1122259899?ean=2940152014235" class="link2"
              target="_blank">E-Book; </a> -->
              <a
              href="https://www.amazon.com/dp/B01680T0P6/ref=cm_sw_r_cp_dp_T1_wQGpzbWJ4ZR1F" class="link2"
              target="_blank">Kindle</a></p>
        </div>


      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
        <div class="wrapper">
          <img src="assets/images/San3@0,25x.jpg" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">San</a></h3>
          <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <!-- <a href="https://www.amazon.com/Mercedes-Bleau/e/B00LUDGSDY" class="link2" target="_blank">E-Book; </a> -->
            <a
              href="https://www.amazon.com/Mercedes-Bleau/e/B00LUDGSDY" class="link2" target="_blank">Kindle</a></p>
        </div>

      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
        <div class="wrapper">
          <img src="assets/images/BoxSetImage-SatiationTrilogy-300x360.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Satiation</a></h3>
          <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <!-- <a href="https://www.barnesandnoble.com/w/satiation-mercedes-bleau/1128789854?ean=2940162127703"
              class="link2" target="_blank">E-Book; </a> -->
              <a href="http://a.co/fiEiQjo" class="link2"
              target="_blank">Kindle</a>
            </p>
        </div>

      </div>
    </div>
  </div>
  <div class="hline v7"></div>
  <h2 class="txt_cntr marTop2"><a id="sa"></a>Stand Alone Stories</h2>


  <div class="row txt_cntr">
    <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
      <div class="wrapper">
        <img src="assets/images/soon.png" alt="" width="270" height="380" class="img5">
        <h3 class="lh24"><a href="#" class="link">Enslaved</a></h3>
        <p>by <a href="http://www.mercedesbleau.com" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
          <!-- <a href="https://www.amazon.com/Mercedes-Bleau/e/B00LUDGSDY" class="link2" target="_blank">E-Book; </a> -->
          <a
            href="https://www.amazon.com/Mercedes-Bleau/e/B00LUDGSDY" class="link2" target="_blank">Kindle</a></p>
      </div>


    </div>

  </div>

</section>