import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-Home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'Home';
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['Prev', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };
  customOptions2: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout : 5000,
    autoplaySpeed: 4500,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['Prev', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };

public camaraImgList: (string | any)[] = [
  { url: 'assets/images/slide1.png', caption: 'Be a bad witch. Wolf Claimed', href: 'http://a.co/jhI0E2f', id: 'a1' },
  { url: 'assets/images/slide2.png', caption: 'Satiation Trilogy By Mercedes Bleau', href: 'http://a.co/fiEiQjo', id: 'a2' },
  { url: 'assets/images/slide3.png', caption: 'Witch Betrayed', href: 'https://www.amazon.com/Witch-Betrayed-Episode-Books-MagKaen-ebook/dp/B01LZU9T98', id: 'a3' },
  { url: 'assets/images/slide4.png', caption: 'Evil Twin by A. Mallory Hughes', href: '', id: 'a4' },
];
constructor(private router: Router) {

  }

  // tslint:disable-next-line: typedef
  ngOnInit() {

  }

}
